import React, {useState} from 'react';
import * as ant from 'antd';
import {Language} from "./Data/types";
import ProjectSection from "./Components/ProjectSection";
import './reset.css'
import './App.css'
import './Components/Components.css'
import './Components/Tooltip.css'
import SkillSection from "./Components/SkillSection";
import AboutSection from "./Components/AboutSection";
import EducationSection from "./Components/EducationSection";
import ContactSection from "./Components/ContactSection";

function App() {

    // https://colorhunt.co/palette/161a3031304db6bbc4f0ece5

    let [language , setLanguage] = useState(Language.en);
    let LanguageButton : string = ""
    const SkillsSectionTitle: string = language === Language.en ? "Skills" : "Compétences";
    const ProjectsSectionTitle: string = language === Language.en ? "Projects" : "Projets";
    const EducationSectionTitle: string = language === Language.en ? "CV" : "CV";

    if(language === Language.en){
        LanguageButton = "Français"
    }
    else{
        LanguageButton = "English"
    }

    function handleClick() {
        if(language === Language.en){
            setLanguage(Language.fr);
        }
        else{
            setLanguage(Language.en);
        }
    }

    return (
        <div className={"App"} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center'}}>
            <ant.ConfigProvider theme={{token:{colorPrimary:"#F0ECE5", fontSize:20, colorText: "#B6BBC4"},components: {Timeline: {dotBg:"#31304D", tailColor: "#B6BBC4"},},}}>
                <div className={"Main"}>
                    <div className={"navbar"}>
                        <nav style={{display:"flex", flexDirection: "column", justifyContent:"flex-start", height:"100%"}}>
                            <p style={{fontSize: "1.5rem", textAlign: "left", paddingLeft: "1.5rem", paddingTop: "1.5rem"}}>Clovis Thouvenot Oudart</p>
                            <ant.Button style={{alignSelf: "center", margin:"1rem", color:"#161A30"}} type="primary" onClick={handleClick}> {LanguageButton} </ant.Button>
                            <ant.Anchor
                                items={[
                                    {
                                        key: 'skills',
                                        href: '#skills',
                                        title: SkillsSectionTitle,
                                    },
                                    {
                                        key: 'projects',
                                        href: '#projects',
                                        title: ProjectsSectionTitle,
                                    },
                                    {
                                        key: 'education',
                                        href: '#education',
                                        title: EducationSectionTitle,
                                    },
                                ]}
                            />
                            <ContactSection lang={language} showTitle={false} align={"start"}/>
                        </nav>
                    </div>
                    <div className={"MainBody"}>
                        <ant.Button className={"SmallPageButton"} type="primary" onClick={handleClick}> {LanguageButton} </ant.Button>
                        <div id={"about"}>
                            <AboutSection lang={language}/>
                        </div>
                        <div id={"skills"}>
                            <SkillSection lang={language}/>
                        </div>
                        <div id={"projects"}>
                            <ProjectSection lang={language}/>
                        </div>
                        <div id={"education"}>
                            <EducationSection lang={language}/>
                        </div>
                        <div id={"contact"}>
                            <ContactSection lang={language} showTitle={true} align={"center"} className={"ContactSection"}/>
                        </div>
                    </div>
                </div>
            </ant.ConfigProvider>
        </div>
    );
}

export default App;
