import { Language } from "../Data/types";
import * as ant from "antd";
import React from "react";

type ContactSectionProps = {
    lang: Language;
    showTitle: boolean;
    align: "start" | "center" | "end" | "baseline" | "stretch";
    className?: string;
}

function ContactSection(props: ContactSectionProps) {
    const lang = props.lang;
    const showTitle = props.showTitle;
    const align = props.align;
    const className = props.className;

    function copyText() {
        navigator.clipboard.writeText("clovis" + ".thouvenot-" + "oudart" + "@efrei" + ".net");
        let tooltip = document.getElementById("myTooltip");
        tooltip!.innerHTML = lang === Language.en ? "Copied email" : "Email copié";
    }
    function outFunc(){
        let tooltip = document.getElementById("myTooltip");
        tooltip!.innerHTML = lang === Language.en ? "Copy" : "Copier";
    }

    const mail: string = "clovis" + ".thouvenot-" + "oudart" + "@efrei" + ".net";

    const SectionTitle: string = "Contact";
    const EmailText: string = lang === Language.en ? "Feel free to contact me:" : "N'hésitez pas à me contacter:";
    const tooltipText: string = lang === Language.en ? "Copy" : "Copier";

    return(
        <ant.Flex className={className} vertical={true} align={align} style={{marginBottom:"2rem", justifySelf:"end", marginTop:"auto"}}>
            {showTitle ? <h1 className={"SectionTitle"}>{SectionTitle}</h1> : null}
            <p style={{fontSize: "1.15rem", textAlign: "left", paddingLeft: "1rem", paddingTop: "1rem", color:"#B6BBC4"}}>{EmailText}</p>
            <button onMouseOut={outFunc} id={"tooltip"} onClick={copyText} className={"tooltip"} style={{fontSize: "0.87rem", textAlign: "left", paddingLeft: "1rem", paddingTop: "0.5rem", cursor:"pointer"}}><span className="tooltiptext" id="myTooltip">{tooltipText}</span>{mail}</button>
            <ant.Flex style={{alignSelf:"center"}}>
                <a className={"link"} target={"_blank"} href={"https://www.linkedin.com/in/clovis-thouvenot-oudart/"} style={{fontSize: "0.87rem", textAlign: "left", paddingLeft: "1rem", paddingTop: "0.5rem"}}>
                    <svg style={{width:'2rem', height: 'auto'}} className="svg-inline--fa fa-linkedin fa-w-14" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="#0e76a8" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path></svg>
                </a>
                <a className={"link"} target={"_blank"} style={{width:'2rem', height: 'auto', marginLeft: "1rem", marginTop: "0.65rem"}} href={"https://www.instagram.com/lemondedecloclo/"}><img alt={""} src={"./Assets/Instagram.svg"}/></a>
            </ant.Flex>
        </ant.Flex>
    )
}

export default ContactSection;