import {Language} from "../Data/types";
import * as ant from "antd";
import React from "react";

type EducationSectionProps = {
    lang: Language
}

function EducationSection(props: EducationSectionProps) {

    const lang = props.lang;

    const educationENG = [
        {
            label: 'June 2019',
            children: <><p>Lycée François 1er</p>
                <p className={"FormationDescription"}>OIB (International Option of the French Baccalaureate), Honorable Mention </p>
                <p className={"FormationDescription"}>IGCSE  First Language English (A*)</p>
                <p className={"FormationDescription"}>IGCSE English Litterature (A)</p>
                <p className={"FormationDescription"}>Fontainebleau, France</p></>
        },
        {
            dot: <img src={"../Assets/UniversiteParis.svg"} alt={""} style={{height:"1.75rem", width: "auto"}}/>,
            label: '2019 - 2021‎ ‎ ',
            children: <><p>Université de Paris</p>
                <p className={"FormationDescription"}>CUPGE (Classes Universitaires Préparatoires aux Grandes Écoles)</p></>,
        },
        {
            dot: <img src={"../Assets/UniversiteConcordia.svg"} alt={""} style={{height:"1.75rem", width: "auto"}}/>,
            label: 'Spring 2022‎ ‎‎',
            children: <><p>Concordia University</p>
                <p className={"FormationDescription"}>Semester abroad as part of EFREI curriculum</p></>,
        },
        {
            label: '2021 - 2024‎ ‎ ',
            children: <><p>EFREI Paris</p>
                <p className={"FormationDescription"}>Software engineering specialising in Imagery and Virtual Reality</p></>,
        },
    ];

    const educationFR = [
        {
            label: 'Juin 2019',
            children: <><p>Lycée François 1er</p>
                <p className={"FormationDescription"}>OIB (Option Internationale du Baccalauréat), Mention Bien</p>
                <p className={"FormationDescription"}>IGCSE  First Language English (A*)</p>
                <p className={"FormationDescription"}>IGCSE English Litterature (A)</p>
                <p className={"FormationDescription"}>Fontainebleau, France</p></>
        },
        {
            dot: <img src={"../Assets/UniversiteParis.svg"} alt={""} style={{height:"1.75rem", width: "auto"}}/>,
            label: '2019 - 2021‎ ‎ ',
            children: <><p>Université de Paris</p>
                <p className={"FormationDescription"}>CUPGE (Classes Universitaires Préparatoires aux Grandes Écoles)</p></>,
        },
        {
            dot: <img src={"../Assets/UniversiteConcordia.svg"} alt={""} style={{height:"1.75rem", width: "auto"}}/>,
            label: 'Printemps 2022‎ ‎‎',
            children: <><p>Concordia University</p>
                <p className={"FormationDescription"}>Semestre à l'étranger dans le cadre du cursus EFREI</p></>,
        },
        {
            label: '2021 - 2024‎ ‎ ',
            children: <><p>EFREI Paris</p>
                <p className={"FormationDescription"}>Ingénieur en informatique, spécialisation Imagerie et Réalité Virtuelle</p></>,
        },];

    const experienceENG = [
        {
            label: 'July 2018',
            children: <><p>Humanitarian stay in Kathmandu, Nepal.</p>
                <p className={"FormationDescription"}>Volunteer work in rebuilding a school destroyed by the 2015 earthquake.</p>
                <p className={"FormationDescription"}>Two weeks total.</p></>,
        },
        {
            label: "November 2022 - March 2023",
            children: <><p>Five month entrepreneurial project (EFREI)</p>
                <p className={"FormationDescription"}>Working in a team of two to develop a technical demo of a VR game (see projects section).</p>
                <p className={"FormationDescription"}>Game design, programming, 3D modelling, sound design, devlogs, business plan, etc.</p></>,
        },
        {
            label: '2022 - 2024',
            children: <><p>Vice-president of school's Acting Club.</p>
                <p className={"FormationDescription"}>Organising and participating in the writing and production of a play each year.</p></>,
        },
        {
            label: 'June - August 2024',
            children: <><p>End-of-studies internship at CEA-List</p>
                <p className={"FormationDescription"}>Research on lighting in gaussian splatting. State of the art and development.</p>
                <p className={"FormationDescription"}>Modification of the Unity pipeline to add shadows and reflections in Gaussian splatting.</p>
                <p className={"FormationDescription"}>Creation of a low-level gaussian splatting raytracer using Nvidia Optix.</p></>,
        }
    ];

    const experienceFR = [
        {
            label: 'Juillet 2018',
            children: <><p>Séjour humanitaire à Katmandou, Népal.</p>
                <p className={"FormationDescription"}>Travail bénévole dans la reconstruction d'une école détruite par le séisme de 2015.</p>
                <p className={"FormationDescription"}>Deux semaines au total.</p></>,
        },
        {
            label: "Novembre 2022 - Mars 2023",
            children: <><p>Projet entrepreneurial de cinq mois (EFREI)</p>
                <p className={"FormationDescription"}>Travail en équipe de deux pour développer une démo technique d'un jeu VR (voir la section projets).</p>
                <p className={"FormationDescription"}>Game design, programmation, modélisation 3D, sound design, communication digitale, business plan, etc.</p></>,
        },
        {
            label: '2022 - 2024',
            children: <><p>Vice-président de l'association de Théâtre de l'école.</p>
                <p className={"FormationDescription"}>Organisation et participation à l'écriture et à la production d'une pièce chaque année.</p></>,
        },
        {
            label: 'Juin - Août 2024',
            children: <><p>Stage de fin d'études au CEA-List</p>
                <p className={"FormationDescription"}>Recherche sur l'éclairage en gaussian splatting. Etat de l'art et développement.</p>
                <p className={"FormationDescription"}>Modification du pipeline Unity pour ajouter ombres et rélfexions en Gaussian splatting.</p>
                <p className={"FormationDescription"}>Création d'un raytracer gaussian splatting bas-niveau grâce à Nvidia Optix.</p></>,
        }
    ];


    const education = lang === Language.en ? educationENG : educationFR;
    const experience = lang === Language.en ? experienceENG : experienceFR;
    const EducationTitle: string = lang === Language.en ? "Education" : "Formation";
    const ExperienceTitle: string = lang === Language.en ? "Experience" : "Expériences";

    return(
        <ant.Flex className={"EducationSection"} align={"center"} vertical={true}>
            <ant.Flex gap={"large"} className={"EducationDiv"}>
                <ant.Flex className={"TimelineContainer"} vertical={true}>
                    <h1 className={"SectionTitle"}>{EducationTitle}</h1>
                    <ant.Timeline className={"Timeline"} items={education} mode={"left"} reverse={true}/>
                </ant.Flex>
                <ant.Flex className={"TimelineContainer"} vertical={true}>
                    <h1 className={"SectionTitle"}>{ExperienceTitle}</h1>
                    <ant.Timeline className={"Timeline"} items={experience} mode={"right"} reverse={true}/>
                </ant.Flex>
            </ant.Flex>
        </ant.Flex>
    )
}

export default EducationSection;