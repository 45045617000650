import {Language} from "../Data/types";
import * as ant from "antd";
import React from "react";
import * as data from "../Data/Data";

type AboutSectionProps = {
    lang: Language
}

function AboutSection(props: AboutSectionProps) {
    const lang = props.lang;

    const description = lang === Language.en ? data.aboutENG : data.aboutFR;

    return(
        <ant.Flex className={"AboutSection"}>
            <div className={"AboutText"} style={{display:"flex", flexDirection:"column",justifyContent:"center"}}>
                <h1 className={"Name"} style={{color: "#F0ECE5", fontSize:"4rem", textAlign:"left"}}>Clovis Thouvenot Oudart</h1>
                <br/>
                <p className={"Description"}>{description}</p>
            </div>
            <div className={"ProfilePhotoContainer"}>
                <img className={"ProfilePhoto"} src={"Assets/THOUVENOT_OUDART_Clovis.png"} alt={"Clovis Thouvenot Oudart"}/>
            </div>
        </ant.Flex>
    )
}

export default AboutSection;