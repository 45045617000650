import {Project, Tag} from "../Data/types";

export const CSharpTag : Tag = {
    color: "rgba(123,75,162,0.6)",
    name: "C#"
}

export const HLSLTag : Tag = {
    color: "rgba(244,67,54,0.6)",
    name: "HLSL"
}

export const LuaTag : Tag = {
    color: "rgba(0,72,186,0.6)",
    name: "Lua"
}

export const UnityTag : Tag = {
    color: "rgba(74,74,74,0.6)",
    name: "Unity"
}

export const CppTag : Tag = {
    color: "rgba(25,116,210,0.6)",
    name: "C++"
}

export const OptixTag : Tag = {
    color: "rgba(118,185,0,0.6)",
    name: "Nvidia Optix"
}

export const CudaTag : Tag = {
    color: "rgba(255,52,0,0.6)",
    name: "CUDA"
}

export const PythonTag : Tag = {
    color: "rgba(255,225,53,0.6)",
    name: "Python"
}

export const VRTag : Tag = {
    color: "rgba(79,77,77,0.6)",
    name: "VR"
}

export const TwoDTag : Tag = {
    color: "rgba(11,160,255,0.6)",
    name: "2D"
}

export const ThreeDTag : Tag = {
    color: "rgba(11,160,255,0.6)",
    name: "3D"
}

export const alhfa : Project = {
    fr: {
        name: "A Little Help from Above",
        description: ["A Little Help From Above est un jeu de plateforme puzzle asymétrique à 2 joueurs. Un joueur PC tente de naviguer à travers des puzzles tandis qu'un joueur VR l'aide en manipulant le monde d'en haut.",
        "Ce projet a été réalisé sur une période de 5 mois en équipe de 2. J'étais en charge de la programmation gameplay du joueur VR, des shaders du jeu, du level design, de l'art et de l'écriture des devlogs.",],
        date: "Nov 2022 - Mars 2023",
        keywords: ["SteamVR", "Monobehaviour", "Pools d'objets", "Variants de prefabs", "Shader Graph", "Pipelines 3D", "Matériaux", "HLSL", "Post processing", "Blender", "Optimisation pour la RV", "Agile Scrum"]
    },
    en: {
        name: "A Little Help from Above",
        description: ["A Little Help From Above is a 2 player asymmetrical puzzle platformer. A PC player attempts to navigate through puzzles while a VR player helps by manipulating the world from above.",
            "This project was made over the span of 5 months in a team of 2. I was in charge of the VR player's gameplay programming, the game's shaders, the level design, art and writing devlogs."],
        date: "Nov 2022 - March 2023",
        keywords: ["SteamVR", "Monobehaviour", "Object Pools", "Prefab variants", "Shader Graph", "URP shaders", "Materials", "HLSL", "Post processing", "Blender", "Optimizing for VR", "Agile Scrum"]
    },
    tags: [CSharpTag, UnityTag, HLSLTag, ThreeDTag, VRTag],
    image: "../Assets/alhfa.gif",
    link: "https://pinkie-games.itch.io/a-little-help-from-above",
    mag_link : null,
}

export const gsray : Project = {
    fr: {
        name: "Raytracer Gaussian Splatting",
        description: ["Pendant mon stage de fin d'études au CEA-List, j'ai travaillé sur un prototype de raytracer pour gaussian splatting. L'objectif était le rendu de scènes en Gaussian splatting grâce au lancer de rayons plutôt que la rastérisatrion. Le lancer de rayons permet des effets de lumière plus complexes (ombres portées, réflexions, réfractions).",
        "J'ai utilisé la librairie Nvidia Optix, implémenté une nouvelle primitive de géométrie (splat gaussienne) et écrit le code CUDA de rendu. Une partie importante du travail concernait l'optimisation du code."],
        date: "Juin - Août 2024",
        keywords: ["Gaussian Splatting", "C++", "CUDA", "Nvidia Optix", "Raytracing", "Optimisation de code"]
    },
    en: {
        name: "Gaussian Splatting Raytracer",
        description: ["During my end-of-studies internship at CEA-List, I worked on a raytracer prototype for gaussian splatting. The goal was to render scenes in Gaussian splatting using raytracing instead of rasterization. Raytracing allows for more complex lighting effects (shadows, reflections, refraction).",
            "I used Nvidia Optix, implemented a new custom primitive (gaussian splat) and wrote the CUDA rendering code. A significant part of the work was code optimization."],
        date: "June - Aug 2024",
        keywords: ["Gaussian Splatting", "C++", "CUDA", "Nvidia Optix", "Raytracing", "Code optimization"]
    },
    tags: [CppTag, OptixTag, CudaTag],
    image: "../Assets/gsray.gif",
    link: null,
    mag_link : null,
}

export const gsunity : Project = {
    fr: {
        name: "Gaussian Splatting sur Unity",
        description: ["Pendant mon stage de fin d'études au CEA-List, j'ai travaillé sur l'implémentation de Gaussian Splatting sur Unity. L'objectif était d'ajouter des ombres portées entre splats gaussiennes et maillages, ainsi que des réflexions.",
            "Le laboratoire avait déjà un plugin de rastérization de splats gaussiennes sur Unity. J'y ai ajouté de nouvelles fonctionnalités. Pour cela j'ai utilisé des Command Buffers de Unity et écrit des shaders HLSL. J'ai utilisé l'algorithme de shadowmaps pour les ombres portées."],
        date: "Avril - Mai 2024",
        keywords: ["Gaussian Splatting", "C#", "Command Buffer", "Shadowmaps", "HLSL", "Built-in Render pipeline", "Réflexion", "Renderdoc"]
    },
    en: {
        name: "Unity Gaussian Splatting",
        description: ["During my end-of-studies internship at CEA-List, I worked on implementing Gaussian Splatting on Unity. The goal was to add shadow casting between gaussian splats and meshes, as well as reflections.",
            "The lab already had a gaussian splat rasterization plugin on Unity. I added new features to it. I used Unity's Command Buffers and wrote HLSL shaders. I used the shadowmaps algorithm for shadow casting."],
        date: "April - May 2024",
        keywords: ["Gaussian Splatting", "C#", "Command Buffer", "Shadowmaps", "HLSL", "Built-in Render pipeline", "Reflection", "Renderdoc"]
    },
    tags: [CSharpTag, UnityTag, HLSLTag],
    image: "../Assets/gsunity.gif",
    link: null,
    mag_link : null,
}

export const pico3d : Project = {
    fr: {
        name: "Rastérisation sur Pico-8",
        description: ["J'ai implémenté un moteur de rendu 3D par rastérisation sur Pico-8. Les maillages sont stockés sous format .obj. Les maillages peuvent être rendus en ombrage plat ou en 'fil de fer'.",
            "Les maillages sont projetés sur un écran de 128x128 pixels. Pour la rastérisation des faces, j'ai implémenté un algorithme utilisant les coordonnées barycentriques, puis un algorithme scanline plus efficient pour les capacités du moteur. L'ombrage est calculé selon un éclairage lambertien. Les deux méthodes permettent un rendu à 30 images par seconde."],
        date: "Janvier 2024",
        keywords: ["Rastérisation", "Structure de données pour maillage", "Algorithme de rendu", "Ombrage plat", "Coordonnées barycentriques", "Algorithme scanline"]
    },
    en: {
        name: "Pico-8 rasterizer",
        description: ["I implemented a 3D rasterization rendering engine on Pico-8. Meshes are stored as .obj files. Meshes can be rendered in flat shading or wireframe.",
            "Meshes are projected on a 128x128 pixel screen. For face rasterization, I implemented an algorithm using barycentric coordinates, then a scanline algorithm, more efficient for the engine's capabilities. Shading is calculated using lambertian lighting. Both methods allow for 30 frames per second rendering."],
        date: "Jan 2024",
        keywords: ["Rasterization", "Mesh data structure", "Rendering algorithm", "Flat shading", "Barycentric coordinates", "Scanline algorithm"]
    },
    tags: [LuaTag, ThreeDTag],
    image: "../Assets/pico-3D.gif",
    link: null,
    mag_link : null,
}

export const colorings : Project = {
    fr: {
        name: "Colorings",
        description: ["Voyagez dans un monde étrange où les anneaux changent votre perception du monde.",
            "Colorings est un jeu de puzzle VR réalisé lors d'une game jam de 48 heures. J'ai travaillé sur la programmation gameplay, les shaders et le level design.",
            "Thème : The world is a ring"],
        date: "Avril 2023",
        keywords: ["UnityXR", "Unity 3D physics", "HLSL", "Optimisation pour la RV", "Camera View Layers", "Attraper des objets en RV", "Mouvement téléporté"]
    },
    en: {
        name: "Colorings",
        description: ["Travel in a strange world where rings change your perception of the world.",
            "Colorings is a VR puzzle game made during a 48-hour game jam. I worked on gameplay programming, shaders and level design.",
            "Theme : The world is a ring"],
        date: "April 2023",
        keywords: ["UnityXR", "Unity 3D physics", "HLSL", "Optimizing for VR", "Camera View Layers", "VR object grabbing", "Smooth Teleport movement"]
    },
    tags: [CSharpTag, UnityTag, HLSLTag, ThreeDTag, VRTag],
        image: "../Assets/colorings.gif",
    link: "https://dominosxpizza.itch.io/coloring",
    mag_link : null,
}

export const laboory : Project = {
    fr: {
        name: "Labo-ory",
        description: ["Stevie le scientifique et Jesse son rat de laboratoire doivent trouver les ingrédients manquants pour leur potion !",
            "Labo-ory est un petit jeu de puzzle coop réalisé lors d'une game jam de 24 heures. J'étais en charge de la programmation.",
            "Thème : Something is missing"],
        date: "Mai 2023",
        keywords: ["Unity 2D", "Singletons", "Events Unity (Observer pattern)", "Tilemaps", "Animations de sprites", "Coopération en écran partagé"]
    },
    en: {
        name: "Labo-ory",
        description: ["Stevie the scientist and Jesse his lab rat need to find the missing ingredients for their potion!",
            "Labo-ory is a short coop puzzle game made during a 24-hour game jam. I was in charge of gameplay programming.",
            "Theme : Something is missing"],
        date: "May 2023",
        keywords: ["Unity 2D", "Singletons", "Unity events (Observer pattern)", "Tilemaps", "Sprite animations", "Split-screen coop"]
    },
    tags: [CSharpTag, UnityTag, TwoDTag],
    image: "../Assets/labo.gif",
    link: "https://codenamewaddles.itch.io/labo-ory",
    mag_link : null,
}

export const plane : Project = {
    fr: {
        name: "Contrôleur aérien",
        description: ["Essayez de gérer le trafic aérien et évitez le maximum de morts possible !",
            "Projet centré sur l'extensibilité et l'adaptativité du code orienté-objet ainsi que la documentation (diagrammes UML). Nous avons décidé d'y ajouter une interface graphique."],
        date: "Fév 2022",
        keywords: ["Programmation orientée-objet", "UML", "Design Patterns", "GUI", "JavaFX"]
    },
    en: {
        name: "Air Traffic Control Simulator",
        description: ["Try to manage air traffic and avoid as many deaths as possible!",
            "Project focused on the scalability and adaptability of object-oriented code as well as documentation (UML diagrams). We decided to add a GUI to it."],
        date: "Feb 2022",
        keywords: ["Object-oriented programming", "UML", "Design Patterns", "GUI", "JavaFX"]
    },
    tags: [],
    image: "../Assets/plane.gif",
    link: "https://github.com/MathPays/Air-Traffic-Controller",
    mag_link : null,
}

export const pizzaPanic : Project = {
    fr: {
        name: "Pizza Panic",
        description: ["Suivez les flèches et livrez autant de pizzas que vous pouvez avant la fin du temps ! Présenté dans le magazine web Pico-View de décembre 2023 !",
            "Un de mes jeux Pico 8 plus compliqués, je me suis amusé à render le monde 3D avec des fonctions très basiques. J'ai implémenté différentes fonctions d'interpolation pour l'UI. Il y a des voitures PNJ qui circulent sur la carte et entrent en collision avec le joueur. J'ai mis de l'effort dans la musique"],
        date: "Août - Nov 2023",
        keywords: []
    },
    en: {
        name: "Pizza Panic",
        description: ["Follow the arrows and deliver as many pizzas as you can before time runs out ! Featured in the web-magazine Pico-View of december 2023 !",
            "One of my more complex Pico 8 games, I had fun rendering the 3D world with very basic tech. I implemented different interpolation functions for the UI. The game has NPC cars that drive around and collide with the player. I also put some effort in the music."],
        date: "Aug - Nov 2023",
        keywords: []
    },
    tags: [LuaTag, TwoDTag],
    image: "../Assets/pizza.gif",
    link: "https://www.lexaloffle.com/bbs/?tid=55233",
    mag_link : "https://nerdyteachers.com/PICO-8/Pico-View/?issue=12#featuredReview",
}

export const dinoDash : Project = {
    fr: {
        name: "Dino Dash",
        description: ["Dino Dash est un endless runner. Esquivez les météorites qui arrivent aussi longtemps que possible.",
            "Ce projet m'a permis de perfectionner les boucles de jeu basiques, de créer des sprites et des animations et d'implémenter une UI basique."],
        date: "Déc 2022",
        keywords: []
    },
    en: {
        name: "Dino Dash",
        description: ["Dino Dash is a simple endless runner. Dodge the incoming meteors for as long as you can.",
            "This project allowed me to perfect basic game loops, create sprites and animations and implement a basic UI."],
        date: "Dec 2022",
        keywords: []
    },
    tags: [LuaTag, TwoDTag],
    image: "../Assets/dino.gif",
    link: "https://www.lexaloffle.com/bbs/?tid=50874",
    mag_link : null,
}

export const spaceRace : Project = {
    fr: {
        name: "Space Race",
        description: ["Faites la course pour le meilleur temps ou essayez de battre vos amis dans ce jeu de course spatial.",
            "Un de mes premiers projets de jeu. Gameplay, art et musique simples. Système de highscore. Multijoueur local."],
        date: "Juin 2022",
        keywords: []
    },
    en: {
        name: "Space Race",
        description: ["Race your ship for the best time or try to beat your friends in this space themed racing game.",
            "One of my first game projects. Simple gameplay, art and music. Highscore system. Local multiplayer."],
        date: "June 2022",
        keywords: []
    },
    tags: [LuaTag, TwoDTag],
    image: "../Assets/spaceRace.gif",
    link: "https://www.lexaloffle.com/bbs/?tid=47991",
    mag_link : null,
}

export const gameJams = [
    colorings,
    laboory,
]

export const persoProjects = [
    pizzaPanic,
    dinoDash,
    spaceRace,
]

export const programmingSkills = [
    "C++",
    "C#",
    "Python",
    "Java",
    "React/Vue",
]

export const technologySkills = [
    "Unity",
    "UnityXR",
    "OpenCV",
    "OpenGL/Vulkan",
    "Blender",
]

export const PMSkillsENG = [
    "Git (Github, Gitlab, Git Bash)",
    "Jira",
    "Trello",
    "UML",
    "Agile Methodologies",
    "Google Workspace",
    "Microsoft Office",
]

export const PMSkillsFR = [
    "Git (Github, Gitlab, Git Bash)",
    "Jira",
    "Trello",
    "UML",
    "Méthodes Agiles",
    "Google Workspace",
    "Microsoft Office",
]

export const languagesENG = [
    "French (Native)",
    "English (Bilingual)",
    "German (B1)",
    "Spanish (A2)",
]

export const languagesFR = [
    "Français (Langue maternelle)",
    "Anglais (Bilingue)",
    "Allemand (B1)",
    "Espagnol (A2)",
]

export const hobbiesENG = [
    "Drama",
    "Video Games",
    "Reading",
    "Climbing",
    "Photography",
]

export const hobbiesFR = [
    "Théâtre",
    "Jeux Vidéo",
    "Lecture",
    "Escalade",
    "Photographie",
]

export const aboutENG = [
    "I'm an aspiring game developer with a knack for graphics programming and technical art. I'm constantly looking for new ways to improve my skills and learn new things. I'm convinced that game development requires both technical skills and an artistic flair."
]

export const aboutFR = [
    "Je suis un jeune développeur de jeux vidéo avec un penchant pour la programmation graphique et le technical art. Je suis constamment à la recherche de nouvelles façons d'améliorer mes compétences et d'apprendre de nouvelles choses. Je suis convaincu que la création de jeu vidéo nécessite des compétences techniques une fibre artistique."
]
