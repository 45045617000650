export enum Language {
    en = 'en',
    fr = 'fr'
}

export type Project = {
    fr: {
        name: string;
        description: string[]
        date: string;
        keywords: string[];
    }
    en: {
        name: string;
        description: string[]
        date: string;
        keywords: string[];
    }
    tags: Array<Tag>;
    image: string;
    link: string | null;
    mag_link : string | null;
}

export type Tag = {
    color: string;
    name: string;
}