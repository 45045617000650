import React from 'react';
import * as ant from 'antd';
import {Language, Project, Tag} from "../Data/types";

type ProjectProps = {
    project: Project,
    lang: Language,
    className?: string,
    isPlane?: boolean
}

function ProjectCard(props: ProjectProps) {
    const {project, lang} = props;
    const className = props.className;
    const isPlane = props.isPlane;

    const description = project[lang].description.map((paragraph:string) =>
        paragraph === project[lang].description[0] ? <><p className={"projectDescription"}>{paragraph}</p><br/><p style={{fontWeight:"bold"}} className={"projectDescription"}>{lang === Language.en ? "More info:" : "Plus d'informations:"}</p><br/></> : <><p className={"projectDescription"}>{paragraph}</p><br/></>);

    const tagList = project.tags.map((tag:Tag) =>
        <ant.Tag key={tag.name} color={tag.color}>{tag.name}</ant.Tag>
    );

    const keywords = project[lang].keywords.map((keyword:string) =>
        <p style={{color:"#ffffff", marginRight:"1.5rem", marginTop:"0.5rem"}}>{keyword}</p>
    );

    const keywordsTitle = project[lang].keywords.length > 0 ? <h1 style={{color:"#ffffff", fontWeight:"bold", marginTop:"0.5rem", marginRight:"1.5rem"}}>{lang === Language.en ? "Keywords:" : "Mots-clés:"}</h1> : null;

    const keywordsSection = project[lang].keywords.length > 0 ? <div className={"Keywords"}>{keywordsTitle}{keywords}</div> : null;

    const planePictures = isPlane ?
        <div className={"DiagramsContainer"}>
            <div className={"DiagramContainerSmall"}><ant.Image className={"PlaneDiagram"} src={"../Assets/AirControlActivity.png"} alt={"Activity diagram"} preview={{src:"../Assets/AirControlActivity2.png"}}></ant.Image></div>
            <div className={"DiagramContainerSmall"}><ant.Image className={"PlaneDiagram"} src={"../Assets/AirTrafficControl.png"} alt={"Class diagram"} preview={{src:"../Assets/Air_Traffic.png"}}></ant.Image></div>
        </div> : null;

    const linkText = isPlane ?  "Git" : lang === Language.en ? "Project page" : "Page du projet";

    return (
        <div className={className}>
            <ant.Card className={"ProjectCard"} bordered={true} bodyStyle={{background: "#31304D"}} headStyle={{background:"#F0ECE5", color: "#161A30"}} type={"inner"} title={<><h1>{project[lang].name}</h1><p style={{fontSize:"1rem", fontStyle:"italic"}}>{project[lang].date}</p></>} extra={project.link ? <a className={"gameLink"} style={{color: "#161A30"}} href={project.link} target={"_blank"}>{linkText}<svg style={{marginLeft:"0.5rem"}} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-external-link" width="22" height="22" viewBox="0 0 24 24" stroke-width="1.5" stroke="#161A30" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6" />
                        <path d="M11 13l9 -9" />
                        <path d="M15 4h5v5" />
                    </svg>
                    </a> : null}>
                <div className={"ProjectCardGrid"}>
                    <div className={"ProjectCardCell"} style={{display: "flex", flexDirection:"column"}}>
                        <img src={project.image} alt={"Game screenshot"} style={{borderRadius:"8px"}}/>
                        <div style={{display: "flex", flexDirection: "row", padding: "10px"}}>
                            {tagList}
                        </div>
                        {keywordsSection}
                    </div>
                    <div className={"ProjectCardCell"} style={{display: "flex", flexDirection:"column", textAlign:"start"}}>
                        {description}
                        {planePictures}
                    </div>
                </div>
            </ant.Card>
        </div>
    )
}

export default ProjectCard;