import {Language} from "../Data/types";
import * as data from "../Data/Data";
import * as ant from "antd";
import React from "react";


type SkillSectionProps = {
    lang: Language
}

function SkillSection(props: SkillSectionProps) {
    const lang = props.lang;

    const SectionTitle: string = lang === Language.en ? "Skills" : "Compétences";
    const TechnicalSectionTitle: string = lang === Language.en ? "Programming" : "Programmation";
    const TechnologiesSectionTitle: string = "Technologies";
    const PMSectionTitle: string = lang === Language.en ? "Project Management" : "Gestion de Projet";
    const LanguagesSectionTitle: string = lang === Language.en ? "Languages" : "Langues";
    const HobbiesSectionTitle: string = lang === Language.en ? "Hobbies" : "Loisirs";

    const ProgrammingSkillsList = data.programmingSkills.map((skill:string) =>
        <div key={skill}>{skill}</div>
    )
    const TechnologiesList = data.technologySkills.map((technology:string) =>
        <li key={technology}>{technology}</li>
    )
    const PMSkillsList = lang === Language.en ? data.PMSkillsENG.map((skill:string) =>
        <li key={skill}>{skill}</li>
    ) : data.PMSkillsFR.map((skill:string) =>
        <li key={skill}>{skill}</li>
    )
    const LanguagesList = lang === Language.en ? data.languagesENG.map((language:string) =>
        <li key={language}>{language}</li>
    ) : data.languagesFR.map((language:string) =>
        <li key={language}>{language}</li>
    )
    const hobbiesList = lang === Language.en ? data.hobbiesENG.map((hobby:string) =>
        <li key={hobby}>{hobby}</li>
    ) : data.hobbiesFR.map((hobby:string) =>
        <li key={hobby}>{hobby}</li>
    )

    return (
        <ant.Flex align={"center"} vertical={true}>
            <h1 className={"SectionTitle"}>{SectionTitle}</h1>
            <ant.Flex gap="small" className={"SkillSectionInside"} justify={"space-around"}>
                <ant.Card bodyStyle={{background: "#31304D", height:"90%", color:"white"}} headStyle={{background:"#F0ECE5", color: "#161A30"}} className={"SkillCard"} title={TechnicalSectionTitle} bordered={false}>
                    <ul className={"SkillList"}>
                        {ProgrammingSkillsList}
                    </ul>
                </ant.Card>
                <ant.Card bodyStyle={{background: "#31304D", height:"90%", color:"white"}} headStyle={{background:"#F0ECE5", color: "#161A30"}} className={"SkillCard"} title={TechnologiesSectionTitle} bordered={false}>
                    <ul className={"SkillList"}>
                        {TechnologiesList}
                    </ul>
                </ant.Card>
                <ant.Card bodyStyle={{background: "#31304D", height:"90%", color:"white"}} headStyle={{background:"#F0ECE5", color: "#161A30"}} className={"SkillCard"} title={PMSectionTitle} bordered={false}>
                    <ul className={"SkillList"}>
                        {PMSkillsList}
                    </ul>
                </ant.Card>
                <ant.Card bodyStyle={{background: "#31304D", height:"90%", color:"white"}} headStyle={{background:"#F0ECE5", color: "#161A30"}} className={"SkillCard"} title={LanguagesSectionTitle} bordered={false}>
                    <ul className={"SkillList"}>
                        {LanguagesList}
                    </ul>
                </ant.Card>
                <ant.Card bodyStyle={{background: "#31304D", height:"90%", color:"white"}} headStyle={{background:"#F0ECE5", color: "#161A30"}} className={"SkillCard"} title={HobbiesSectionTitle} bordered={false}>
                    <ul className={"SkillList"}>
                        {hobbiesList}
                    </ul>
                </ant.Card>
            </ant.Flex>
        </ant.Flex>
    )
}

export default SkillSection;